import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Button, Box, Grid, Card, Heading, Text, Divider } from "theme-ui";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import HomeHero from "../components/HomeHero";
import SEO from '../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneLaptop, faCode, faStore, faServer, faUserShield, faHandsHelping } from '@fortawesome/pro-duotone-svg-icons';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Expert Web Design and Development Agency in Surrey" description="Thinkanew Media is a Surrey-based web design and application development agency who work in partnership with their customers, actively helping to drive the growth of their businesses. Services include WordPress & Gatsby web design and development, web application development, WordPress support & maintenance, G Suite & Exclaimer Cloud setup, as well as other supporting IT services." keywords={['web', 'design', 'development', 'application', 'app', 'website', 'leatherhead', 'dorking', 'guildford', 'epsom', 'surrey']} mdxType="SEO" />
    <HomeHero mdxType="HomeHero" />
    <Box sx={{
      pt: [4, 5, 5],
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
      <Heading as='h1' sx={{
        fontSize: [3, 4, 4],
        textAlign: ["inherit", "inherit", "inherit"],
        color: "heading",
        pl: ["0px", "0px", "0px"],
        pr: ["0px", "0px", "0px"]
      }} mdxType="Heading">Thinkanew Media is a Surrey-based web design and application development agency</Heading>
      <div sx={{
        textAlign: ["inherit", "inherit", "inherit"],
        height: "20px"
      }}><hr width="10%" style={{
          marginTop: "4%",
          marginBottom: "4%"
        }} /></div>
      <Text sx={{
        fontSize: [3, 4, 4],
        textAlign: ["inherit", "inherit", "inherit"],
        color: "heading",
        mt: "0px",
        mb: "0px",
        pl: ["0px", "0px", "0px"],
        pr: ["0px", "0px", "0px"]
      }} mdxType="Text">We will work with you, in partnership, to deliver the best possible solution for your company</Text>
    </Box>
    <Box sx={{
      pb: [4, 5, 5],
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
  <Grid gap={[4, 4, 4]} columns={[1, 2, 3]} mdxType="Grid">
    <Box sx={{
          bg: "textWhite",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }} mdxType="Box">
      <Card mdxType="Card">
        <Box sx={{
              bg: "white",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              height: ["190px", "190px", "190px"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }} mdxType="Box">
          <AniLink fade title="Learn more about our web design and development services" duration={0.35} to={`/web-design-development/`} style={{
                textDecoration: "inherit",
                color: "inherit",
                padding: "10px 20px"
              }} mdxType="AniLink">
            <div style={{
                  display: 'table',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
              <FontAwesomeIcon icon={faPhoneLaptop} size="4x" color="#0c6ea5" mdxType="FontAwesomeIcon" />
            </div>
            <Heading as='h2' variant="blockheading" mdxType="Heading">Web Design & Development</Heading>
          </AniLink>
        </Box>
        <Text as="p" variant="blocktext" mdxType="Text">We design and develop customised websites using WordPress, or if performance is paramount and your budget allows, we use GatsbyJS to build fully bespoke, blazing fast, SEO-friendly websites like this one.</Text>
      </Card>
    </Box>
    <Box sx={{
          bg: "textWhite",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }} mdxType="Box">
      <Card mdxType="Card">
        <Box sx={{
              bg: "white",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              height: ["190px", "190px", "190px"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }} mdxType="Box">
          <AniLink fade title="Learn more about our web app development services" duration={0.35} to={`/web-app-development/`} style={{
                textDecoration: "inherit",
                color: "inherit",
                padding: "10px 20px"
              }} mdxType="AniLink">
            <div style={{
                  display: 'table',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
              <FontAwesomeIcon icon={faCode} size="4x" color="#0c6ea5" mdxType="FontAwesomeIcon" />
            </div>
            <Heading as='h2' variant="blockheading" mdxType="Heading">Web Application Development</Heading>
          </AniLink>
        </Box>
        <Text as="p" variant="blocktext" mdxType="Text">We build web applications for businesses of all sizes, with expertise in the full development stack: server provisioning, API design/deployment and front-end client development across a range of popular devices.</Text>
      </Card>
    </Box>
    <Box sx={{
          bg: "textWhite",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }} mdxType="Box">
      <Card mdxType="Card">
        <Box sx={{
              bg: "white",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              height: ["190px", "190px", "190px"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }} mdxType="Box">
          <AniLink fade title="Learn more about our support, maintenance and consultancy services" duration={0.35} to={`/support-maintenance/`} style={{
                textDecoration: "inherit",
                color: "inherit",
                padding: "10px 20px"
              }} mdxType="AniLink">
            <div style={{
                  display: 'table',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
              <FontAwesomeIcon icon={faUserShield} size="4x" color="#0c6ea5" mdxType="FontAwesomeIcon" />
            </div>
            <Heading as='h2' variant="blockheading" mdxType="Heading">Support and Maintenance</Heading>
          </AniLink>
        </Box>
        <Text as="p" variant="blocktext" mdxType="Text">Maintenance and support is key to keeping your business systems up and running. Our services are designed to keep your WordPress website or web application secure and running at peak performance.</Text>
      </Card>
    </Box>
    <Box sx={{
          bg: "textWhite",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }} mdxType="Box">
      <Card mdxType="Card">
        <Box sx={{
              bg: "textWhite",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              height: ["190px", "190px", "190px"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }} mdxType="Box">
          <AniLink fade title="Learn more about our hosting and email services" duration={0.35} to={`/hosting-and-email/`} style={{
                textDecoration: "inherit",
                color: "inherit",
                padding: "10px 20px"
              }} mdxType="AniLink">
            <div style={{
                  display: 'table',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
              <FontAwesomeIcon icon={faServer} size="4x" color="#0c6ea5" mdxType="FontAwesomeIcon" />
            </div>
            <Heading as='h2' variant="blockheading" mdxType="Heading">Hosting and Email</Heading>
          </AniLink>
        </Box>
        <Text as="p" variant="blocktext" mdxType="Text">We can host your website or web application on our fast, reliable and secure infrastructure. We can also help your company set up Google G Suite and add professional email signatures with Exclaimer Cloud.</Text>
      </Card>
    </Box>
    <Box sx={{
          bg: "textWhite",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px"
        }} mdxType="Box">
      <Card mdxType="Card">
        <Box sx={{
              bg: "white",
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              height: ["190px", "190px", "190px"],
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }} mdxType="Box">
          <AniLink fade title="Learn more about our IT Consulting services" duration={0.35} to={`/consulting/`} style={{
                textDecoration: "inherit",
                color: "inherit",
                padding: "10px 20px"
              }} mdxType="AniLink">
            <div style={{
                  display: 'table',
                  marginLeft: 'auto',
                  marginRight: 'auto'
                }}>
              <FontAwesomeIcon icon={faHandsHelping} size="4x" color="#0c6ea5" mdxType="FontAwesomeIcon" />
            </div>
            <Heading as='h2' variant="blockheading" mdxType="Heading">IT Consulting</Heading>
          </AniLink>
       </Box>
        <Text as="p" variant="blocktext" mdxType="Text">We offer a range of technical consulting services to help guide you to the right solutions for your business. Our services range from hardware/software choices to virtual meeting production support.</Text>
      </Card>
    </Box>   
  </Grid>
  <h2 style={{
        textAlign: "center",
        marginTop: "40px"
      }}>Our work actively helps to drive the growth of your business</h2>
    </Box>
    <Box sx={{
      pt: 0,
      pb: 0,
      pl: [3, 5, "80px"],
      pr: [3, 5, "80px"]
    }} mdxType="Box">
    <Divider mdxType="Divider" />
    <h2 style={{
        textAlign: "center",
        marginTop: "40px"
      }}>Like what you see and ready to chat?</h2>
  <Button variant="cta" sx={{
        marginTop: [4, 5, 5],
        marginBottom: [3, 3, 3],
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
        display: "block"
      }} mdxType="Button">
    <AniLink fade title="Get in touch with Thinkanew Media" duration={0.35} to={`/contact-us/`} style={{
          textDecoration: "inherit",
          color: "inherit",
          padding: "14px 20px"
        }} mdxType="AniLink">
    Get in touch
    </AniLink>
  </Button>  
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      